body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link {
  color: gray !important;
}


a.bloglink {
  color: rgb(106, 106, 106);
  text-decoration: none;
}

a:hover.bloglink {
  color: gray;
}

a.bloglink-heavy {
  color: black;
  text-decoration: none;
}

a:hover.bloglink-heavy {
  color: rgb(39, 38, 38);
}

a.blog-link {
    color: rgb(106, 106, 106);
    text-decoration: none;
}

a:hover.blog-link {
    color: gray;
}

a.blog-link-heavy {
    color: black;
    text-decoration: none;
}

a:hover.blog-link-heavy {
    color: rgb(39, 38, 38);
}
  
.row {
  margin-top: 2px;
  margin-bottom: 2px;
}

div.card-footer {
  font-size: 14px;
}

div.app-pane {
  max-width: 900px;
}

pre.code {
  color: #ffffff;
  background: #4b4b4b;
  padding: 10px;
  border: #797979;
  border-radius: 5px;
}

pre.blog-code {
    color: #ffffff;
    background: #4b4b4b;
    padding: 10px;
    border: #797979;
    border-radius: 5px;
  }
  
blockquote.blogquote {
  margin-left: 5px;
  border-left: 3px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

a.page-link {
  color: black;
}

span.page-link {
  background-color: #a3a3a3 !important;
  border-color: #a3a3a3 !important;
}

h1 {
  font-size: 1.5rem !important;
}

h2 {
  font-size: 1.25rem !important;
}

h3 {
  font-size: 1.125rem !important;
}

span.badge {
  margin-right: 5px;
}

.card-center {
  text-align: center;
}